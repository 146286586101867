import { useMemo, useEffect } from 'react'
import {
  generatePath,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import queryString from 'query-string'
import { consts } from '@wiz/utils'

export {
  BrowserRouter as Router,
  Link,
  matchPath,
  Prompt,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'

export const RoutesThree = [
  {
    id: 'base',
    path: '/:lang?',
    name: 'home',
  },
  {
    id: 'login',
    path: '/:lang?/login',
    name: 'login',
  },
  {
    id: 'logoutcb',
    path: '/:lang?/logoutcb',
    name: 'logoutcb',
  },
  {
    id: 'logout',
    path: '/:lang?/logout',
    name: 'logout',
    private: true,
  },
  {
    id: 'admin',
    name: 'admin',
    breadcrumb: 't/breadcrumb.admin',
    children: [
      {
        id: 'admin-roles',
        path: '/:lang?/admin/roles',
        name: 'roles-list',
        access: 'SectionRoles',
        breadcrumb: 't/breadcrumb.roles',
        private: true,
      },
      {
        id: 'admin-users',
        path: '/:lang?/admin/users',
        name: 'users-list',
        access: 'SectionUsers',
        breadcrumb: 't/breadcrumb.users',
        private: true,
      },
    ],
  },
  {
    id: 'dsapi',
    path: '/:lang?/dsapi',
    name: 'dsapi',
    manageAccess: false,
    private: true,
  },
  {
    id: 'docs',
    path: '/:lang?/docs',
    name: 'docs',
    manageAccess: false,
    private: true,
  },
  {
    id: 'auth-docs',
    path: '/:lang?/auth-docs',
    name: 'auth-docs',
    manageAccess: false,
    private: true,
  },
  {
    id: 'ailab',
    name: 'ailab',
    path: '/:lang?/ailab',
    breadcrumb: 'breadcrumb.ailab',
    private: true,
    children: [
      {
        id: 'ailab-templates',
        path: '/:lang?/ailab/templates',
        name: 'templates-list',
        access: 'SectionTemplates', // FIXME:
        private: true,
        exact: true,
        breadcrumb: 't/breadcrumb.templates',
        children: [
          {
            id: 'ailab-template',
            path: '/:lang?/ailab/templates/:id',
            name: 'template',
            access: 'Experiment', // FIXME:
            manageAccess: false,
            private: true,
          },
        ],
      },
      {
        id: 'ailab-experiments',
        path: '/:lang?/ailab/experiments',
        name: 'experiments-list',
        access: 'SectionExperiments',
        breadcrumb: 't/breadcrumb.experiments',
        private: true,
        exact: true,
        children: [
          {
            id: 'ailab-experiment',
            path: '/:lang?/ailab/experiments/:id',
            name: 'experiment',
            access: 'Experiment',
            manageAccess: false,
            private: true,
          },
        ],
      },
      {
        id: 'ailab-business-labels',
        path: '/:lang?/ailab/business_labels',
        name: 'business-labels-list',
        access: 'SectionBusinessLabels',
        breadcrumb: 't/breadcrumb.businessLabels',
        private: true,
        exact: true,
      },
      {
        id: 'ailab-components',
        path: '/:lang?/ailab/components',
        name: 'components-list',
        access: 'SectionComponents',
        breadcrumb: 't/breadcrumb.components',
        private: true,
        exact: true,
      },
      {
        id: 'ailab-dashboards',
        path: '/:lang?/ailab/dashboards',
        name: 'dashboards',
        access: 'SectionDashboards',
        breadcrumb: 't/breadcrumb.dashboards',
        private: true,
        exact: true,
        children: [
          {
            id: 'dashboard',
            path: '/:lang?/ailab/dashboards/:id',
            name: 'dashboard',
            access: 'SectionDashboards',
            manageAccess: false,
            private: true,
          },
        ],
      },
      {
        id: 'ailab-pipelines',
        name: 'pipelines',
        path: '/:lang?/ailab/pipelines',
        breadcrumb: 'breadcrumb.pipelines',
        private: true,
        children: [
          {
            id: 'pipelines-list',
            path: '/:lang?/ailab/pipelines-list',
            name: 'pipelines-list',
            access: 'SectionPipelines',
            breadcrumb: 't/breadcrumb.pipelines',
            private: true,
            exact: true,
            children: [
              {
                id: 'pipeline',
                path: '/:lang?/ailab/pipelines/:id',
                name: 'pipeline',
                access: 'Pipeline',
                manageAccess: false,
                private: true,
              },
            ],
          },
          {
            id: 'ailab-ml-models',
            path: '/:lang?/ailab/mlmodels',
            name: 'ml-models-list',
            access: 'SectionExperiments', // FIXME:
            breadcrumb: 't/breadcrumb.mlmodels',
            private: true,
            exact: true,
            children: [
              {
                id: 'ailab-ml-model',
                path: '/:lang?/ailab/mlmodels/:id',
                name: 'ml-model',
                access: 'MLModel',
                manageAccess: false,
                private: true,
              },
            ],
          },
          {
            id: 'ailab-execution-scripts',
            path: '/:lang?/ailab/scripts',
            name: 'execution-scripts-list',
            access: 'SectionExecutionScripts',
            breadcrumb: 't/breadcrumb.executionScripts',
            private: true,
            exact: true,
            children: [
              {
                id: 'ailab-execution-script',
                path: '/:lang?/ailab/scripts/:id',
                name: 'execution-script',
                access: 'ExecutionScript',
                manageAccess: false,
                private: true,
              },
            ],
          },
          {
            id: 'ailab-plots',
            path: '/:lang?/ailab/plots',
            name: 'plots-list',
            access: 'SectionPlots',
            breadcrumb: 't/breadcrumb.plots',
            private: true,
            exact: true,
            children: [
              {
                id: 'ailab-plot',
                path: '/:lang?/ailab/plots/:id',
                name: 'plot',
                access: 'Plot',
                manageAccess: false,
                private: true,
              },
            ],
          },
        ],
      },
      {
        id: 'ailab-stream-jobs',
        name: 'stream-jobs',
        path: '/:lang?/ailab/stream_jobs',
        breadcrumb: 'breadcrumb.streamJobs',
        private: true,
        children: [
          {
            id: 'stream_jobs',
            path: '/:lang?/ailab/stream_jobs',
            name: 'stream-jobs-list',
            access: 'SectionStreamJobs',
            breadcrumb: 't/breadcrumb.streamJobs',
            private: true,
            exact: true,
          },
          {
            id: 'stream_job',
            path: '/:lang?/ailab/stream_jobs/:id',
            name: 'stream-jobs-view',
            access: 'SectionStreamJobs',
            manageAccess: false,
            private: true,
          },
          {
            id: 'ailab-alerts',
            path: '/:lang?/ailab/alerts',
            name: 'alerts-list',
            access: 'SectionAlerts',
            breadcrumb: 't/breadcrumb.alerts',
            private: true,
          },
          {
            id: 'ailab-logs',
            path: '/:lang?/ailab/logs',
            breadcrumb: 't/breadcrumb.logs',
            private: true,
            children: [
              {
                id: 'logs-sent_email',
                path: '/:lang?/ailab/sent_email',
                name: 'sent-email-logs-list',
                access: 'SectionSentEmailLogs',
                breadcrumb: 't/breadcrumb.sentEmailLogs',
                private: true,
              },
              {
                id: 'logs-sent_sms',
                path: '/:lang?/ailab/sent_sms',
                name: 'sent-sms-logs-list',
                access: 'SectionSentSMSLogs',
                breadcrumb: 't/breadcrumb.sentSMSLogs',
                private: true,
              },
              {
                id: 'logs-stream_jobs',
                path: '/:lang?/ailab/stream_jobs_logs',
                name: 'stream-job-logs-list',
                access: 'SectionStreamJobLogs',
                breadcrumb: 't/breadcrumb.streamJobLogs',
                private: true,
              },
              {
                id: 'logs-edge',
                path: '/:lang?/ailab/edge',
                name: 'edge-logs-list',
                access: 'SectionEdgeLogs',
                breadcrumb: 't/breadcrumb.edgeLogs',
                private: true,
              },
            ],
          },
        ],
      },

      {
        id: 'ailab-solutions',
        name: 'solutions',
        path: '/:lang?/ailab/solutions',
        breadcrumb: 'breadcrumb.solutions',
        private: true,
        children: [
          {
            id: 'executions',
            path: '/:lang?/ailab/executions',
            name: 'executions-list',
            access: 'SectionHistoricalRuns',
            breadcrumb: 't/breadcrumb.historicalRuns',
            private: true,
            exact: true,
            children: [
              {
                id: 'execution',
                path: '/:lang?/ailab/executions/:id',
                name: 'execution',
                access: 'Execution',
                manageAccess: false,
                private: true,
              },
            ],
          },
        ],
      },
      {
        id: 'ailab-triggers',
        path: '/:lang?/ailab/triggers',
        name: 'triggers-list',
        access: 'SectionTriggers',
        breadcrumb: 't/breadcrumb.triggers',
        private: true,
        exact: true,
        children: [
          {
            id: 'ailab-trigger',
            path: '/:lang?/ailab/triggers/:id',
            name: 'trigger',
            access: 'Trigger',
            manageAccess: false,
            private: true,
          },
        ],
      },
      {
        id: 'dev-projects',
        path: '/:lang?/dev/projects',
        name: 'projects-list',
        access: 'SectionProjects',
        breadcrumb: 't/breadcrumb.projects',
        private: true,
      },
      {
        id: 'dev-project',
        path: '/:lang?/dev/projects/:id',
        name: 'projects-view',
        access: 'SectionProjects',
        manageAccess: false,
        private: true,
      },
    ],
  },
  {
    id: 'controlpanel',
    path: '/:lang?/controlpanel',
    name: 'controlpanel',
    // access: 'Sectioncontrolpanel', FIXME:
    breadcrumb: 't/breadcrumb.controlpanel',
    private: true,
    children: [
      {
        id: 'de',
        path: '/:lang?/controlpanel/de/:id?',
        name: 'de',
        access: 'SectionDataExplorer',
        breadcrumb: 't/breadcrumb.data',
        breadcrumbParams: { id: 'reset' },
        private: true,
      },
    ],
  },
  {
    id: 'dev',
    name: 'dev',
    path: '/:lang?/dev',
    breadcrumb: 'breadcrumb.dev',
    private: true,
    children: [
      {
        id: 'ailab-dataframes',
        path: '/:lang?/ailab/dataframes',
        name: 'dataframes-list',
        access: 'SectionExperiments', // FIXME:
        breadcrumb: 't/breadcrumb.dataframes',
        private: true,
        exact: true,
        children: [
          {
            id: 'dev-dataframe',
            path: '/:lang?/dev/dataframes/:id',
            name: 'dataframe',
            access: 'Dataframe',
            manageAccess: false,
            private: true,
          },
        ],
      },
      {
        id: 'dev-events',
        path: '/:lang?/dev/events',
        name: 'events-list',
        access: 'SectionEvents',
        breadcrumb: 't/breadcrumb.events',
        private: true,
      },
      {
        id: 'dev-exportresult',
        path: '/:lang?/dev/exportresult',
        name: 'exportresult-list',
        access: 'SectionExportResults',
        breadcrumb: 't/breadcrumb.exportresult',
        private: true,
      },
      {
        id: 'dev-files',
        path: '/:lang?/dev/files',
        name: 'files-list',
        access: 'SectionFiles',
        breadcrumb: 't/breadcrumb.files',
        private: true,
      },
      {
        id: 'dev-formulas',
        path: '/:lang?/dev/formulas',
        name: 'formulas-list',
        access: 'SectionFormulas',
        breadcrumb: 't/breadcrumb.formulas',
        private: true,
      },
      {
        id: 'dev-jobs',
        path: '/:lang?/dev/jobs',
        name: 'jobs-list',
        access: 'SectionJobs',
        breadcrumb: 't/breadcrumb.jobs',
        private: true,
      },
      {
        id: 'dev-ml_web_services',
        path: '/:lang?/dev/ml_web_services',
        name: 'ml-web-services-list',
        access: 'SectionMlWebServices',
        breadcrumb: 't/breadcrumb.mlWebServices',
        private: true,
      },
      {
        id: 'dev-notebooks',
        path: '/:lang?/dev/notebooks',
        name: 'notebooks-list',
        access: 'SectionNotebooks',
        breadcrumb: 't/breadcrumb.notebooks',
        private: true,
      },
      {
        id: 'dev-notification_sheets',
        path: '/:lang?/dev/notification_sheets',
        name: 'notification-sheets-list',
        access: 'SectionNotificationSheets',
        breadcrumb: 't/breadcrumb.notificationSheets',
        private: true,
      },
      {
        id: 'dev-scripts',
        path: '/:lang?/dev/scripts',
        name: 'scripts-list',
        access: 'SectionScripts',
        breadcrumb: 't/breadcrumb.scripts',
        private: true,
      },
      {
        id: 'dev-solutions-view',
        path: '/:lang?/dev/solutions-view',
        breadcrumb: 't/breadcrumb.solutionsView',
        name: 'solutions-view-list',
        // access: 'SectionGrafanaDashboards',
        manageAccess: false,
        private: true,
      },
    ],
  },
  {
    id: 'edge',
    path: '/:lang?/edge',
    breadcrumb: 't/breadcrumb.edge',
    private: true,
    children: [
      {
        id: 'edge-devices',
        path: '/:lang?/edge/devices',
        name: 'edge-devices-list',
        access: 'SectionEdgeDevices',
        breadcrumb: 't/breadcrumb.edge_devices',
        private: true,
        exact: true,
        children: [
          {
            id: 'edge-device',
            path: '/:lang?/edge/devices/:id',
            name: 'edge-devices',
            access: 'SectionEdgeDevices',
            manageAccess: false,
            breadcrumb: 't/breadcrumb.modules',
            private: true,
          },
        ],
      },
      {
        id: 'edge-telemetry',
        path: '/:lang?/edge/telemetry',
        name: 'edge-telemetry-list',
        access: 'SectionEdgeTelemetry',
        breadcrumb: 't/breadcrumb.edge_telemetry',
        private: true,
      },
      {
        id: 'edge-commands',
        path: '/:lang?/edge/commands',
        name: 'edge-commands-list',
        access: 'SectionDeviceCommands',
        breadcrumb: 't/breadcrumb.edge_commands',
        private: true,
        exact: true,
      },
      {
        id: 'edge-command_templates',
        path: '/:lang?/edge/command_templates',
        name: 'edge-command-templates-list',
        access: 'SectionDeviceCommandTemplates',
        breadcrumb: 't/breadcrumb.edge_command_templates',
        private: true,
        exact: true,
      },
    ],
  },
  {
    id: 'notifications',
    path: '/:lang?/notifications',
    name: 'notifications-list',
    access: 'SectionNotifications',
    breadcrumb: 't/breadcrumb.notifications',
    private: true,
  },
  {
    id: 'settings',
    path: '/:lang?/settings',
    name: 'settings',
    access: 'SectionGeneralSettings',
    breadcrumb: 't/breadcrumb.settings',
    private: true,
    children: [
      {
        id: 'settings-tmpl',
        path: '/:lang?/settings/tmpl',
        breadcrumb: 't/breadcrumb.tmpl',
        private: true,
        children: [
          {
            id: 'settings-tmpl-notebooks',
            path: '/:lang?/settings/tmpl/notebooks',
            name: 'tmpl-notebooks-list',
            access: 'SectionDigitalTwinTmplNote',
            breadcrumb: 't/breadcrumb.tmplNotebooks',
            private: true,
          },
          {
            id: 'settings-tmpl-quality-data',
            path: '/:lang?/settings/tmpl/quality-data',
            name: 'tmpl-quality-data-list',
            access: 'SectionTmplQualityData',
            breadcrumb: 't/breadcrumb.tmplQualityData',
            private: true,
          },
        ],
      },
      {
        id: 'settings-twin',
        path: '/:lang?/settings/twin',
        breadcrumb: 't/breadcrumb.twin',
        private: true,
        children: [
          {
            id: 'settings-twin-style',
            path: '/:lang?/settings/style',
            name: 'twin-style',
            access: 'SectionDigitalTwinStyle',
            breadcrumb: 't/breadcrumb.digital_twin_style',
            private: true,
          },
        ],
      },
      {
        id: 'settings-integrations',
        path: '/:lang?/settings/integrations',
        name: 'integrations-list',
        access: 'SectionGeneralSettings', // FIXME:
        breadcrumb: 't/breadcrumb.integrations',
        private: true,
      },
    ],
  },
  {
    id: 'datahub',
    path: '/:lang?/datahub',
    breadcrumb: 't/breadcrumb.datahub',
    redirect: 'chart',
    private: true,
    children: [
      {
        id: 'twin-data',
        path: '/:lang?/datahub/explorer/:id?',
        name: 'data-observation',
        access: 'SectionDataExplorer',
        breadcrumb: 't/breadcrumb.data',
        breadcrumbParams: { id: 'reset' },
        private: true,
      },
      {
        id: 'datahub-charts',
        path: '/:lang?/datahub/chart',
        name: 'chart-list',
        access: 'SectionDigitalTwinChart',
        breadcrumb: 't/breadcrumb.chart',
        private: true,
        exact: true,
        children: [
          {
            id: 'datahub-chart',
            path: '/:lang?/datahub/chart/:id',
            name: 'chart-view',
            access: 'SectionDigitalTwinChart',
            manageAccess: false,
            private: true,
          },
        ],
      },
      {
        id: 'datahub-items',
        path: '/:lang?/datahub/items',
        name: 'twin-items-list',
        access: 'SectionDigitalTwinItems',
        breadcrumb: 't/breadcrumb.twinItems',
        private: true,
      },
      {
        id: 'datahub-sensors',
        path: '/:lang?/datahub/sensors',
        name: 'sensors-list',
        access: 'SectionDigitalTwinSensors',
        breadcrumb: 't/breadcrumb.sensors',
        private: true,
      },
      {
        id: 'datahub-quality',
        path: '/:lang?/datahub/quality',
        name: 'quality-list',
        access: 'SectionQualityData',
        breadcrumb: 't/breadcrumb.quality',
        private: true,
      },
      {
        id: 'datahub-categories',
        path: '/:lang?/datahub/categories',
        name: 'categories-list',
        access: 'SectionDigitalTwinCategories',
        breadcrumb: 't/breadcrumb.categories',
        private: true,
      },
      {
        id: 'datahub-units',
        path: '/:lang?/datahub/units',
        name: 'units-list',
        access: 'SectionDigitalTwinUnits',
        breadcrumb: 't/breadcrumb.units',
        private: true,
      },
      {
        id: 'datahub-style',
        path: '/:lang?/datahub/style',
        name: 'twin-style',
        access: 'SectionDigitalTwinStyle',
        breadcrumb: 't/breadcrumb.digital_twin_style',
        private: true,
      },
      {
        id: 'datahub-labels',
        path: '/:lang?/datahub/labels',
        name: 'labels-list',
        access: 'SectionLabels',
        breadcrumb: 't/breadcrumb.labels',
        private: true,
      },
    ],
  },
  {
    id: 'user',
    name: 'user',
    children: [
      {
        id: 'user-homepage',
        path: '/:lang?/user/homepage',
        name: 'homepage',
        private: true,
      },
      {
        id: 'user-dashboard',
        path: '/:lang?/user/dashboard',
        redirect: 'homepage',
        private: true,
      },

      {
        id: 'user-profile',
        path: '/:lang?/user/profile',
        name: 'profile',
        breadcrumb: 't/breadcrumb.profile',
        private: true,
      },
    ],
  },
  {
    id: 'notfound',
    path: '/:lang?/*',
    name: 'not-found',
    private: true,
  },
]

function reqThreePlain (items) {
  const routes = []
  for (const item of items) {
    if (item.path) {
      routes.push(item)
    }
    if (item.children) {
      routes.push(...reqThreePlain(item.children))
    }
  }
  return routes
}

export const Routes = reqThreePlain(RoutesThree)

export function createLocation ({
  hash,
  keepQuery,
  name,
  params,
  path,
  query,
  state,
}) {
  let currentQuery = query
  if (keepQuery && window.location.search) {
    currentQuery = {
      ...query,
      ...queryString.parse(window.location.search),
    }
  }

  const search = currentQuery ? queryString.stringify(currentQuery, {
    arrayFormat: 'comma',
    skipEmptyString: true,
    skipNull: true,
  }) : undefined

  let route = name ? Routes.find(item => item.name === name) : undefined
  if (route && route.redirect) {
    route = Routes.find(item => item.name === route.redirect)
  }

  return {
    pathname: route || path ? generatePath(route?.path || path, { ...params }) : undefined,
    search: search ? `?${search}` : undefined,
    hash,
    state,
  }
}

export function useLocationQuery () {
  const location = useLocation()
  const query = useMemo(() => queryString.parse(location.search, {
    arrayFormat: 'comma',
  }), [ location.search ])
  return query
}

export function useRouter () {
  const history = useHistory()
  const match = useRouteMatch()
  const immutableParams = JSON.stringify(match.params)
  const router = useMemo(() => ({
    push ({ params, ...rest }, state) {
      const nextParams = { ...match.params, ...params }
      const lang = (
        nextParams.lang ||
        (consts.Langs.includes(nextParams[0]) ? nextParams[0] : 'en')
      )

      const loc = createLocation({
        ...rest,
        params: {
          ...nextParams,
          lang,
        },
        path: match.path,
      })

      history.push(loc, state)
    },

    reload () {
      history.go(0)
    },

    replace ({ params, ...rest }) {
      const loc = createLocation({
        ...rest,
        params: { ...match.params, ...params },
        path: match.path,
      })
      history.replace(loc)
    },

    back () {
      history.goBack()
    },
  }), [ history, immutableParams ])

  return router
}

export function RouterObserver ({ onChange }) {
  const location = useLocation()

  useEffect(() => {
    onChange?.(location)
  }, [ onChange, location ])

  return null
}
